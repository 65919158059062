import theme from '@src/styles/theme';

const UpArrowIcon = ({ color = theme?.primaryColor?.mutedGray }: { color?: string }) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.9405 6.71094L10.0505 11.6009C9.47305 12.1784 8.52805 12.1784 7.95055 11.6009L3.06055 6.71094'
      stroke={color}
      strokeWidth='1.125'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default UpArrowIcon;
