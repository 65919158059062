import styled from 'styled-components';

import { TextField } from '@mui/material';

import { Breakpoints } from '@src/styles/breakpoints';
import { theme } from '@src/styles/theme';

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: end;
  gap: 16px;
  margin: ${theme.fontSize.get('l')};
`;

export const StyledToggleFieldContainer = styled.div`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const StyledError = styled.div`
  color: ${theme.primaryColor.red};
  line-height: 1.66;
  font-size: ${theme.fontSize.get('2xs')};
  letter-spacing: 0.00033em;
  margin: 3px 14px 0 14px;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const StyledPhoneInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  .country-name {
    display: none;
  }
`;

export const StyledPhoneInputButton = styled.div`
  width: 90%;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid ${theme.primaryColor.lunarElegance};
`;

export const StyledButton = styled.button<{
  $bgColor?: string;
  color?: string;
  border?: boolean;
  display?: string;
  $isBlueButton?: boolean;
}>`
  width: fit-content;
  text-wrap: nowrap;
  align-self: center;
  border-radius: 8px;
  border: ${({ border }) => (border ? `1px solid ${theme.primaryColor.shadePurple}` : 'none')};
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : theme.primaryColor.shadePurple)};
  color: ${({ color }) => (color ? color : theme.primaryColor.white)};
  text-align: center;
  padding: 12px 27px;
  font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  cursor: pointer;
  display: ${({ display }) => display};
  ${({ $isBlueButton }) => ($isBlueButton ? `padding:12px 34px ;` : ``)};

  &:disabled {
    background-color: ${theme.primaryColor.aquaBlue};
    cursor: not-allowed;
  }
`;

export const StyledHeading = styled.div`
  font-size: ${theme.fontSize.get('m')};
  font-weight: 600;
  font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  padding-bottom: 32px;
`;

export const StyledLabel = styled.div`
  font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  color: ${theme.primaryColor.mutedGray};
  font-weight: 500;
  font-size: ${theme.fontSize.get('xs')};
`;

export const StyledCheckBox = styled.input`
  width: 16px;
  height: 16px;
  margin-left: 0px;
`;

export const StyledCheckBoxContainer = styled.div`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 10px;
`;

export const StyledInputFieldContainer = styled.div<{ $gridColumn?: string; $gridRow?: string }>`
  grid-column: ${({ $gridColumn }) => $gridColumn && $gridColumn};
  grid-row: ${({ $gridRow }) => $gridRow && $gridRow};
  padding: 0px 16px;
  ${Breakpoints.TABLET} {
    grid-column: 1/1;
  }
`;

export const StyledInput = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.primaryColor.lunarElegance};
    border-radius: 6px;
  }
  .MuiFormHelperText-root {
    color: ${theme.primaryColor.red};
    font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .MuiInputBase-root {
    ${({ rows }) => (rows ? `min-height: 44px;` : `height: 44px;`)}
    background-color: ${theme.primaryColor.pristineSnowfall};
  }
  .MuiFormLabel-root {
    top: 0px;
    color: ${theme.primaryColor.darkBlue};
    font-family: ${theme.fontFamily.lato}, ${theme.fontFamily.seasons};
  }
`;

export const StyledGridbox = styled.div<{ $gridColumn?: string; $columnGap?: string }>`
  display: grid;
  grid-template-columns: ${({ $gridColumn }) => ($gridColumn ? $gridColumn : 'repeat(3, 1fr)')};
  gap: 24px;
  ${({ $columnGap }) => $columnGap && `column-gap: ${$columnGap};`}
  ${Breakpoints.TABLET} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSection = styled.div<{ border?: boolean }>`
  background-color: ${theme.primaryColor.white};
  ${({ border }) => (border && border ? `border: 1px solid ${theme.primaryColor.softBlue};border-radius: 16px;` : '')}
  padding: ${theme.fontSize.get('l')};
  margin: ${theme.fontSize.get('l')};
  gap: 24px;
`;

export const StyledControlsContainer = styled.div`
  background-color: ${theme.primaryColor.whiteSmoke};
  padding: 1% 8%;
  ${Breakpoints.TABLET_SMALL} {
    padding: 0.5%;
  }
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const StyledSubHeading = styled.div`
  font-weight: 500;
  font-size: ${theme.fontSize.get('m')};
  color: ${theme.primaryColor.mutedGray};
  padding: 16px;
  padding-bottom: 8px;
`;

export const StyledSelectOuter = styled(StyledInput)<{ $isMulti?: boolean }>`
  position: absolute !important;
  .MuiFormLabel-root {
    top: -7px;
  }
  .MuiInputLabel-shrink {
    top: 0px;
  }
  ${({ $isMulti }) =>
    $isMulti &&
    `.MuiInputBase-root {
    height: inherit;
    min-height:44px;
  }
  height: 100%;`}
  input {
    display: none;
  }
  width: 100%;
`;

export const extraStyles = {
  '@media screen and (max-width: 890px)': {
    '& label': {
      color: `${theme.primaryColor.white} !important`,
    },
    '& label.Mui-focused': {
      color: theme.primaryColor.white,
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      color: theme.primaryColor.white,
      backdropFilter: `blur(${theme.fontSize.get('xl')})`,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.primaryColor.white,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.primaryColor.white,
      },
    },
    '& input:-webkit-autofill': {
      WebkitTextFillColor: `${theme.primaryColor.white} !important`,
    },
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 100px transparent inset !important',
    transition: 'background-color 5000s ease-in-out 0s',
  },
};

export const datePickerExtraStyles = {
  '& .MuiInputBase-root': {
    height: '44px',
  },
  '& .MuiFormLabel-root': {
    top: '-4px',
    color: theme.primaryColor.darkBlue,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.primaryColor.lunarElegance}`,
    borderRadius: '6px',
  },
  '& .MuiInputLabel-shrink': {
    top: '3px',
  },
};
