import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce, size } from 'lodash';

import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { FilterIcon, SearchIcon } from '@src/components/Icons';
import FilterRemoveIcon from '@src/components/Icons/filterRemoveIcon';
import FilterModal, { FilterOptions, FilterSelectOptions } from '@src/components/Modals/FilterModal';
import { theme } from '@src/styles/theme';

import { Date as DateType } from '../Modals/EventDetailModal';

import {
  StyledClearButton,
  StyledHelmetContainer,
  StyledIcon,
  StyledItemContainer,
  StyledLeftContainer,
  StyledMenuItem,
  StyledRightContainer,
  StyledSearchBar,
  StyledSearchInput,
  StyledText,
} from './style';

export interface HelmetProps {
  search?: {
    onSearch: (textToSearch: string) => void;
    onClearSearch: () => void;
    placeholder?: string;
  };
  copy?: {
    onCopy: () => void;
    icon?: React.ReactNode;
    text: string;
  };
  filters?: {
    filterOptions: FilterOptions[];
    setFilterValue: Dispatch<SetStateAction<FilterSelectOptions>>;
    handleFilter: () => void;
    clearFilter: () => void;
    defaultFilter: FilterSelectOptions;
  };
  exportCSV?: {
    text: string;
    handleExport: () => void;
  };
  ctaButton?: {
    text: string;
    icon?: React.ReactNode;
    handleCTA: () => void;
  };
  subHeader?: string;
  showBackButton?: boolean;
  date: DateType;
  setDate: Dispatch<SetStateAction<DateType>>;
}

const OrderHistoryHelmet = ({
  search,
  filters,
  exportCSV,
  ctaButton,
  subHeader,
  copy,
  showBackButton = false,
  date,
  setDate,
}: HelmetProps) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [showSearchBar, setSearchBar] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showRemovefilter, setRemovefilter] = useState<boolean>(false);
  const IconComponent = ctaButton?.icon;

  const debounceSearch = useCallback(
    debounce((text: string) => {
      search?.onSearch(text);
    }, 300),
    []
  );

  const clearInput = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.value = '';
      search?.onClearSearch();
      setSearchBar(false);
    }
  };

  return (
    <StyledHelmetContainer>
      <StyledRightContainer>
        {showBackButton && (
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
        <StyledMenuItem>{subHeader}</StyledMenuItem>
      </StyledRightContainer>
      <StyledLeftContainer>
        {size(filters?.defaultFilter) && showRemovefilter ? (
          <StyledItemContainer
            onClick={() => {
              filters?.clearFilter(), setRemovefilter(false);
            }}
          >
            <FilterRemoveIcon />
          </StyledItemContainer>
        ) : null}
        {showSearchBar ? (
          <StyledSearchBar>
            <StyledSearchInput
              type='text'
              placeholder={search?.placeholder ? search?.placeholder : 'Search...'}
              onChange={(event: { target: { value: string } }) => debounceSearch(event.target.value)}
              ref={inputRef}
              autoFocus
            />
            <StyledClearButton onClick={clearInput}>X</StyledClearButton>
          </StyledSearchBar>
        ) : null}

        {search && !showSearchBar ? (
          <StyledItemContainer
            onClick={() => {
              setSearchBar(true);
            }}
          >
            <SearchIcon color={theme.primaryColor.sereneSky} />
          </StyledItemContainer>
        ) : null}

        {filters && (
          <>
            <StyledItemContainer onClick={() => setIsOpen(true)}>
              <FilterIcon color={theme.primaryColor.sereneSky} />
            </StyledItemContainer>
            <FilterModal
              date={date}
              setDate={setDate}
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
              filterData={filters?.filterOptions}
              handleApply={() => {
                filters?.handleFilter(), setIsOpen(false);
                setRemovefilter(true);
              }}
              filterValue={filters?.defaultFilter}
              setFilterValue={filters?.setFilterValue}
            />
          </>
        )}
        {exportCSV && (
          <StyledItemContainer>
            <StyledIcon src={''} alt='export' />
            <StyledText>{exportCSV.text}</StyledText>
          </StyledItemContainer>
        )}
        {copy ? (
          <StyledItemContainer
            onClick={() => {
              copy?.onCopy();
            }}
          >
            {copy?.icon}
            <StyledText>{copy.text}</StyledText>
          </StyledItemContainer>
        ) : null}
        {ctaButton && (
          <StyledItemContainer
            onClick={() => {
              ctaButton?.handleCTA();
            }}
          >
            {IconComponent ? IconComponent : null}
            <StyledText>{ctaButton.text}</StyledText>
          </StyledItemContainer>
        )}
      </StyledLeftContainer>
    </StyledHelmetContainer>
  );
};

export default OrderHistoryHelmet;
