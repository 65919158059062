import { combineReducers } from 'redux';

import bookingDetailsReducer from './bookingDetails';
import eventDetailsReducer from './eventDetails';
import invoiceDetailsReducer from './invoiceDetails';
import journeyStatusReducer from './journey';
import loaderReducer from './loader';
import loginReducer from './login';

const reducer = combineReducers<any>({
  loginReducer,
  loaderReducer,
  eventDetailsReducer,
  invoiceDetailsReducer,
  journeyStatusReducer,
  bookingDetailsReducer,
});

export default reducer;
