import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const StyledText = styled.h3`
  color: ${({ theme }) => theme.primaryColor.royalBlue};
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-family: ${({ theme }) => theme.fontFamily.lato}, ${({ theme }) => theme.fontFamily.seasons};
  font-weight: 700;
`;
