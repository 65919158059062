import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableState } from 'mui-datatables';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { NO_FILTER_RESULTS_MATCH } from '@src/lib/constants';

const muiCache = createCache({
  key: 'mui-datatables',
  prepend: true,
});

interface DatatableProps {
  data: Record<string, string | string[] | boolean | number | Date | JSX.Element | JSX.Element[] | undefined>[];
  headers: MUIDataTableColumnDef[];
  handleRowClick?: (data: any, row: { dataIndex: number }) => void;
  //@TODO need to change
  handleTableChange?: (action: string, tableState: MUIDataTableState) => void;
  rowsPerPage?: number;
  count?: number;
  page?: number;
  setSortBy?: any;
}

const OrderHistoryTable = ({
  data,
  headers,
  count,
  rowsPerPage,
  handleTableChange,
  handleRowClick,
  page,
  setSortBy,
}: DatatableProps) => {
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    serverSide: true,
    selectableRows: 'none' as const,
    tableBodyMaxHeight: '',
    count: count,
    page: page,
    textLabels: {
      body: {
        noMatch: NO_FILTER_RESULTS_MATCH,
      },
    },
    tableBodyHeight: '64vh',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 30],
    pagination: true,
    onTableChange: handleTableChange,
    onRowClick: handleRowClick,
    onColumnSortChange: (changedColumn: string, direction: 'asc' | 'desc') => {
      if (changedColumn === 'bookedAt') {
        setSortBy({
          sort: direction === 'desc' ? -1 : 1,
          fieldName: changedColumn,
        });
      }
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <CacheProvider value={muiCache}>
        <MUIDataTable title={''} data={data} columns={headers} options={options} />
      </CacheProvider>
    </div>
  );
};

export default OrderHistoryTable;
