import { theme } from '@src/styles/theme';

interface MenuBoardProps {
  isSelected?: boolean;
  isHovered?: boolean;
}

const MenuBoard = ({ isSelected = false, isHovered = false }: MenuBoardProps) => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.1027 6.19659L17.0135 18.5991C16.7935 19.525 15.9685 20.1666 15.0152 20.1666H2.9702C1.58603 20.1666 0.596048 18.8099 1.00855 17.4807L4.86771 5.08746C5.13354 4.2258 5.93105 3.62988 6.82939 3.62988H18.1044C18.9752 3.62988 19.6994 4.16155 20.0019 4.89489C20.176 5.28905 20.2127 5.73826 20.1027 6.19659Z'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M14.6665 20.1667H19.0482C20.2307 20.1667 21.1565 19.1675 21.074 17.985L20.1665 5.5'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.87305 5.84838L9.82639 1.88843'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0151 5.85745L15.8768 1.87915'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.05859 11H14.3919'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1416 14.6667H13.4749'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MenuBoard;
