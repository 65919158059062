import { StyledContainer, StyledText } from './style';

interface NoDataFoundProps {
  message?: string;
}

const NoDataFound = ({ message = 'No data found' }: NoDataFoundProps): JSX.Element => {
  return (
    <StyledContainer>
      <StyledText>{message}</StyledText>
    </StyledContainer>
  );
};

export default NoDataFound;
