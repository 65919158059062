import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Footer from '@src/components/Footer';
import Header from '@src/components/Header';
import useMediaQuery from '@src/hooks/useMediaQuery';
import { pageTitleMap } from '@src/lib/constants';
import { routesConfigType } from '@src/lib/types';
import { ReduxProps } from '@src/redux/type';
import { StyledMainWrapper } from '@src/styles/mainLayoutWrapper';

const PublicRoute = ({ config }: { config: routesConfigType }) => {
  const {
    component: Component,
    styles = true,
    isFooter,
    bgColor,
    isHeaderSticky,
    isHeader = true,
    isMobileHeaderSticky = isHeaderSticky,
    headerBackGround,
  } = config;

  const { isUserAuthenticated } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  const location = useLocation();
  const isDesktop = useMediaQuery(800);

  // Get the current path from the location object
  const currentPath = location.pathname;

  useEffect(() => {
    if (pageTitleMap['/' + currentPath.split('/')[1]]) {
      document.title = pageTitleMap['/' + currentPath.split('/')[1]];
    }
  }, [location.pathname]);

  return styles ? (
    <StyledMainWrapper $bgColor={bgColor}>
      {isHeader ? (
        <Header
          isSticky={isDesktop ? isHeaderSticky : isMobileHeaderSticky}
          isLoggedIn={isUserAuthenticated}
          headerBackGround={headerBackGround}
        />
      ) : null}
      <Component />
      {isFooter && <Footer />}
    </StyledMainWrapper>
  ) : (
    <>
      {isHeader ? <Header isSticky={isHeaderSticky} /> : null}
      <Component />
    </>
  );
};

export default PublicRoute;
