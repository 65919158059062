const CalendarIcon = () => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.33398 1.83301V3.83301'
      stroke='#4B5563'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.666 1.83301V3.83301'
      stroke='#4B5563'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.33398 6.55957H13.6673'
      stroke='#4B5563'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 6.16634V11.833C14 13.833 13 15.1663 10.6667 15.1663H5.33333C3 15.1663 2 13.833 2 11.833V6.16634C2 4.16634 3 2.83301 5.33333 2.83301H10.6667C13 2.83301 14 4.16634 14 6.16634Z'
      stroke='#4B5563'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.4625 9.63314H10.4685' stroke='#4B5563' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.4625 11.6331H10.4685' stroke='#4B5563' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.99764 9.63314H8.00363' stroke='#4B5563' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.99764 11.6331H8.00363' stroke='#4B5563' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.52889 9.63314H5.53488' stroke='#4B5563' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.52889 11.6331H5.53488' stroke='#4B5563' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default CalendarIcon;
