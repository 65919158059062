import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

import Footer from '@src/components/Footer';
import Header from '@src/components/Header';
import { pageTitleMap, UiRoutes } from '@src/lib/constants';
import { routesConfigType } from '@src/lib/types';
import { ReduxProps } from '@src/redux/type';
import { StyledMainWrapper } from '@src/styles/mainLayoutWrapper';

const PrivateRoute = ({ config }: { config: routesConfigType }) => {
  const buttonParam = useSearchParams()[0].get('button');
  const oneTimePayParam = useSearchParams()[0].get('oneTimePay');
  const isButtonParamValid = buttonParam && buttonParam === 'true';
  const isOneTimePayParamValid = oneTimePayParam && oneTimePayParam === 'true';
  const { id: searchId } = useParams();
  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    if (pageTitleMap['/' + currentPath.split('/')[1]]) {
      document.title = pageTitleMap['/' + currentPath.split('/')[1]];
    }
  }, [location.pathname]);

  const { component: Component, styles = true, isFooter, bgColor, isHeaderSticky } = config;
  const { isUserAuthenticated } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  const AuthenticatedLayout = (
    <StyledMainWrapper $bgColor={bgColor}>
      <Header isSticky={isHeaderSticky} isLoggedIn={true} oneTimePay={!!oneTimePayParam} />
      <Component />
      {isFooter && <Footer />}
    </StyledMainWrapper>
  );

  const UnauthenticatedRedirect = <Navigate to={`${UiRoutes.LOGIN}?redirect=${searchId}&button=true`} replace />;

  return isUserAuthenticated ? (
    styles ? (
      AuthenticatedLayout
    ) : (
      <Component />
    )
  ) : isButtonParamValid ? (
    UnauthenticatedRedirect
  ) : isOneTimePayParamValid ? (
    AuthenticatedLayout
  ) : (
    <Navigate to={UiRoutes.PACKAGESFS} replace />
  );
};

export default PrivateRoute;
