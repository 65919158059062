import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import reducers from '../reducer';
import rootSaga from '../saga';

const sagaMiddleware: any = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loaderReducer'],
  // Add any other config here. For example, you might want to add a whitelist or a blacklist.
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancer =
  typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(sagaMiddleware)));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persistor, store };
