import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledOverlay = styled.div`
  background: #25282b;
  opacity: 0.4;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
`;

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const StyledModalDialog = styled.div`
  display: flex;
  margin: 30px auto;
  border-radius: 6px;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  padding: 20px 40px;
`;

export const StyledImageContainer = styled.div`
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Breakpoints.TABLET_SMALL} {
    width: 130px;
    height: 130px;
  }
`;

export const StyledLoadingText = styled.span`
  font-weight: 500;
  text-align: center;
`;
