import { lazy } from 'react';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { UiRoutes } from '@src/lib/constants';
import { routesConfigType } from '@src/lib/types';
import { theme } from '@src/styles/theme';

const Login = lazy(() => import('../screens/Login'));
const HorizontalStepper = lazy(() => import('@src/components/StepManager'));
const PackagesFS = lazy(() => import('../screens/PackagesFS'));
const PackagesDC = lazy(() => import('../screens/PackagesDC'));
const OrderDetails = lazy(() => import('../screens/OrderDetailScreen'));
const Delivery = lazy(() => import('@src/screens/Delivery'));
const ChooseMeal = lazy(() => import('@src/screens/ChooseMeal'));
const DropOffChooseMealScreen = lazy(() => import('@src/screens/DropoffChooseMeal'));
const ThankYouCard = lazy(() => import('@src/screens/Thankyou'));
const OrderSummary = lazy(() => import('@src/screens/OrderSummary'));
const Payment = lazy(() => import('@src/screens/Payment'));
const Profile = lazy(() => import('@src/screens/ProfileManagement/detail'));
const EditProfile = lazy(() => import('@src/screens/ProfileManagement/edit'));
const OrderHistory = lazy(() => import('@src/screens/OrderHistory'));
const RateUs = lazy(() => import('@src/screens/RateUs'));
const QrCode = lazy(() => import('@src/screens/QrCode'));
const MenuOfTheDay = lazy(() => import('@src/screens/MenuOfTheDay'));
const DishReview = lazy(() => import('@src/screens/DishReview'));
const EventFeedback = lazy(() => import('@src/screens/EventFeedback'));
const FSCMenu = lazy(() => import('@src/screens/FSCMenu'));
const OneTimePayment = lazy(() => import('@src/screens/OneTimePayment'));

const routesConfig = (): Array<routesConfigType> => {
  const isDesktop = useMediaQuery(480);

  return [
    {
      path: UiRoutes.LOGIN,
      component: Login,
      layout: 'public',
      styles: false,
      isHeader: false,
    },
    // {
    //   path: UiRoutes.HOME,
    //   component: Home,
    //   layout: 'public',
    // },
    {
      path: UiRoutes.STEPPERS,
      component: HorizontalStepper,
      layout: 'private',
    },
    {
      path: UiRoutes.PACKAGESFS,
      component: PackagesFS,
      layout: 'public',
      isFooter: true,
      isHeaderSticky: true,
      isMobileHeaderSticky: false,
    },
    {
      path: UiRoutes.PACKAGESDC,
      component: PackagesDC,
      layout: 'public',
      isFooter: true,
      isHeaderSticky: true,
      isMobileHeaderSticky: false,
    },
    {
      path: UiRoutes.DELIVERY,
      component: Delivery,
      layout: 'private',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.ORDER_SUMMARY,
      component: OrderSummary,
      layout: 'private',
      // layout: 'public',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.THANKYOU,
      component: ThankYouCard,
      layout: 'public',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.ORDER_DETAILS,
      component: OrderDetails,
      layout: 'public',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.CHOOSE_MEAL,
      component: ChooseMeal,
      layout: 'public',
    },
    {
      path: UiRoutes.DROP_OFF_CHOOSE_MEAL,
      component: DropOffChooseMealScreen,
      layout: 'public',
    },
    {
      path: UiRoutes.PAYMENT,
      component: Payment,
      layout: 'private',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.RATE_US,
      component: RateUs,
      layout: 'public',
      bgColor: isDesktop ? theme.primaryColor.white : theme.primaryColor.GlacierMist,
      headerBackGround: isDesktop ? theme.primaryColor.white : theme.primaryColor.GlacierMist,
    },
    {
      path: UiRoutes.QR_CODE,
      component: QrCode,
      layout: 'public',
      isHeader: false,
      bgColor: 'radial-gradient(#255573, #18384d)',
    },
    {
      path: UiRoutes.MENU_OF_THE_DAY,
      component: MenuOfTheDay,
      layout: 'public',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.DISH_DETAILS,
      component: DishReview,
      layout: 'public',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.ADDONS_DETAILS,
      component: DishReview,
      layout: 'public',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.PROFILE,
      component: Profile,
      layout: 'private',
    },
    {
      path: UiRoutes.EDIT_PROFILE,
      component: EditProfile,
      layout: 'private',
    },
    {
      path: UiRoutes.ORDER_HISTORY,
      component: OrderHistory,
      layout: 'private',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.EVENT_FEEDBACK,
      component: EventFeedback,
      layout: 'private',
      bgColor: theme.primaryColor.whiteSmoke,
    },
    {
      path: UiRoutes.MENU,
      component: FSCMenu,
      layout: 'public',
      isFooter: true,
    },
    {
      path: UiRoutes.ONE_TIME_PAYMENT,
      component: OneTimePayment,
      layout: 'private',
      isFooter: false,
      isHeader: false,
    },
  ];
};

export default routesConfig;
