export const SpaceTShirtSizes = ['none', '3xs', '2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl'] as const;
export const AllPairSizes = SpaceTShirtSizes.flatMap(callbackSize =>
  SpaceTShirtSizes.map(size => (callbackSize !== size ? `${callbackSize}-${size}` : null)).filter(
    (value): value is string => !!value
  )
);
export type TShirtSizeSpace = 'none' | '3xs' | '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl';

export type TShirtSize = typeof SpaceTShirtSizes[number];
export type PairSize = typeof AllPairSizes[number];
export type SpaceSize = TShirtSize | PairSize;
export type Space = Map<SpaceSize, string>;
export type Scale = Map<SpaceSize, number>;

const scale: Scale = new Map([
  ['none', 0],
  ['3xs', 0.25],
  ['2xs', 0.5],
  ['xs', 0.75],
  ['s', 1],
  ['m', 1.5],
  ['l', 2],
  ['xl', 3],
  ['2xl', 4],
  ['3xl', 6],
]);

export const fluid_min_width = 320;
export const fluid_max_width = 2560;
const fluid_screen = `min(100vw, ${fluid_max_width}px)`;
const fluid_breakpoint = `calc((${fluid_screen} - ${fluid_min_width} / 16 * 1rem) / (${fluid_max_width} - ${fluid_min_width}))`;

const makeSpaceMap = (min: number, max: number) => {
  // Create the space map
  const space: Space = new Map();

  SpaceTShirtSizes.forEach(key => {
    const value = scale.get(key);

    if (typeof value === 'undefined') {
      return;
    }

    space.set(key, `calc(((${min * value} / 16) * 1rem) + (${max * value} - ${min * value}) * ${fluid_breakpoint})`);
  });

  AllPairSizes.forEach(key => {
    const [key1, key2] = key.split('-');
    const value1 = scale.get(key1);
    const value2 = scale.get(key2);

    if (typeof value1 === 'undefined' || typeof value2 === 'undefined') {
      return;
    }

    space.set(key, `calc(((${min * value1} / 16) * 1rem) + (${max * value2} - ${min * value1}) * ${fluid_breakpoint})`);
  });

  return space;
};

export const space = makeSpaceMap(14, 18.67);
