import { useEffect } from 'react';
import OTPInput from 'react-otp-input';

import Modal from '@mui/material/Modal';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { filterNumeric } from '@src/lib/helper';
import { closeIcon } from '@src/lib/imgUrl';
import { StyledOtpInput } from '@src/screens/Login/style';
import theme from '@src/styles/theme';

import {
  StyledClose,
  StyledHeaderContainer,
  StyledHeading,
  StyledInputs,
  StyledMainContainer,
  StyledMessage,
} from '../SaveProgressModal/styles';

import {
  StyledButton,
  StyledCountDownContainer,
  StyledDash,
  StyledResendText,
  StyledResendTextContainer,
} from './styles';

export interface OtpStateProps {
  countdown: number;
  otp: string;
  isOtpValid: boolean;
}

export interface ApiMessageProps {
  successMessage: string;
  errorMessage: string;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (values: string) => void;
  message: string;
  heading: string;
  handleResend: () => void;
  otpState: OtpStateProps;
  setOtpState: React.Dispatch<React.SetStateAction<OtpStateProps>>;
  setSaveForLater?: React.Dispatch<React.SetStateAction<boolean>>;
  apiMessages?: ApiMessageProps;
}

const OtpVerificationModal = ({
  isOpen,
  onRequestClose,
  onSave,
  message,
  heading,
  handleResend,
  otpState,
  setOtpState,
  setSaveForLater,
  apiMessages,
}: ModalProps) => {
  const isDesktop = useMediaQuery(380);

  const handleVerify = () => {
    onSave(otpState.otp);
  };

  const handleClose = () => {
    onRequestClose();
    setSaveForLater && setSaveForLater(false);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && otpState?.isOtpValid) {
      handleVerify();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [otpState?.isOtpValid]);

  return (
    <Modal
      open={isOpen}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(7px)',
        },
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledMainContainer>
        <StyledHeaderContainer>
          <StyledHeading>{heading}</StyledHeading>
          <StyledClose src={closeIcon} onClick={handleClose}></StyledClose>
        </StyledHeaderContainer>
        <StyledMessage>{message}</StyledMessage>
        <StyledInputs>
          <OTPInput
            shouldAutoFocus={true}
            value={otpState.otp}
            onChange={value => {
              const alphanumericOtp = filterNumeric(value);
              setOtpState(prevState => ({
                ...prevState,
                otp: alphanumericOtp,
                isOtpValid: alphanumericOtp.length === 6,
              }));
            }}
            inputType='tel'
            inputStyle={{
              color: '#3790B3',
              fontFamily: theme.fontFamily.lato,
              fontSize: '24px',
              fontWeight: '600',
              ...(isDesktop ? {} : { width: '85%' }),
            }}
            numInputs={6}
            renderInput={prop => <StyledOtpInput {...prop} placeholder='0' $hasValue={!!prop.value} />}
          />
        </StyledInputs>
        {apiMessages?.successMessage && !apiMessages?.errorMessage && otpState.countdown > 0 && (
          <StyledMessage>{apiMessages?.successMessage}</StyledMessage>
        )}
        {!!apiMessages?.errorMessage && (
          <StyledMessage error={!!apiMessages?.errorMessage}>{apiMessages?.errorMessage}</StyledMessage>
        )}
        {otpState?.countdown > 0 && (
          <StyledCountDownContainer>
            Code expires in <b>{otpState?.countdown}</b> seconds
          </StyledCountDownContainer>
        )}
        <StyledMessage>Please note: The OTP SMS will be sent from the sender ID ‘DishCater’.</StyledMessage>
        <StyledButton type='button' disabled={!otpState.isOtpValid} onClick={handleVerify}>
          Verify
        </StyledButton>
        {otpState?.countdown === 0 && (
          <StyledResendTextContainer>
            <StyledDash />
            <StyledMessage>
              Didn't receive the code? <StyledResendText onClick={handleResend}>Resend</StyledResendText>
            </StyledMessage>
            <StyledDash />
          </StyledResendTextContainer>
        )}
      </StyledMainContainer>
    </Modal>
  );
};

export default OtpVerificationModal;
