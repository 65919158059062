import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps } from '@src/lib/getUpdate';
import { CreatePackagesEventParams } from '@src/model/packages';

import apiRequest from './api';

export const getPackageById = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_PACKAGE}/${id}`,
  });
};

export const getPackageList = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PACKAGE_LIST}`,
    config: { params },
  });
};

export const createPackagesEvent = (data: CreatePackagesEventParams) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_EVENT}`,
    data,
    config: {
      skipErrorMessage: true,
    },
  });
};

export const deletePackagesEvent = (data: DataProps) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.DELETE_EVENT}`,
    data,
  });
};
