import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';
import { StyledFullWidthWrapper } from '@src/styles/mainLayoutWrapper';

export const StyledHelmentContainer = styled(StyledFullWidthWrapper)`
  background: linear-gradient(to bottom, ${({ theme }) => theme.primaryColor.shadePurple} 0%, #18384d 100%);
  height: 100px;
  position: relative;
  padding: 24px calc(${({ theme }) => theme.space.get('2xl')}*3.1);
  ${Breakpoints.DESKTOP} {
    padding: 24px calc(${({ theme }) => theme.space.get('2xl')}*2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 24px ${({ theme }) => theme.space.get('s')};
  }
  ${Breakpoints.MOBILE} {
    height: 120px;
  }
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  margin-top: calc(45px * -1);
`;

export const StyledStatusBtn = styled.div<{ color: string }>`
  padding: 4px 12px;
  width: fit-content;
  border: none;
  border-radius: 6px;
  background-color: ${props => props.color};
`;

export const StyledPaymentStatusBtn = styled.div<{ color: string }>`
  padding: 4px 12px;
  width: fit-content;
  border: none;
  border-radius: 6px;
  background-color: ${props => props.color};
`;

export const buttonStyle = {
  '&.MuiButtonBase-root': {
    padding: 0,
  },
};
