import * as yup from 'yup';

import { uaePhoneNumberRegex } from '@src/lib/constants';

const validationSchema = yup.object({
  mobileNumber: yup.string().matches(uaePhoneNumberRegex, 'Please enter a valid mobile number'),
  // email: yup
  //   .string()
  //   .email('Invalid email format')
  //   .matches(validEmail, 'Invalid email address')
  //   .required('Email is required'),
});

export { validationSchema };
