const FilterRemoveIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    fill='red'
    stroke='red'
    strokeWidth={0}
    viewBox='0 0 32 32'
  >
    <g stroke='none'>
      <path d='M30 11.414 28.586 10 24 14.586 19.414 10 18 11.414 22.586 16 18 20.585 19.415 22 24 17.414 28.587 22 30 20.587 25.414 16 30 11.414z' />
      <path d='M4 4a2 2 0 0 0-2 2v3.17a2 2 0 0 0 .586 1.415L10 18v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.83l-.586-.585L4 9.171V6h20v2h2V6a2 2 0 0 0-2-2Z' />
      <path
        d='M0 0h32v32H0z'
        data-name='&lt;Transparent Rectangle&gt;'
        style={{
          fill: 'none',
        }}
      />
    </g>
  </svg>
);

export default FilterRemoveIcon;
