import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { loginOtp, verifyLoginOtp } from '@src/api/login';
import { FormValues } from '@src/components/FormComponent/types';
import {
  DROP_OFF,
  SAVE_LATER_MODAL_DESC,
  SAVE_LATER_MODAL_HEADING,
  statusCodes,
  UiRoutes,
  WORDPRESS_BASE_URL,
} from '@src/lib/constants';
import { startLoaderAction, stopLoaderAction, userAction } from '@src/redux/action/login';
// import { clearEventDetails } from '@src/redux/action/eventDetails';
// import { clearInvoiceDetails } from '@src/redux/action/invoiceDetails';
// import { clearJourney } from '@src/redux/action/journeyStatus';
// import { clearUserData, startLoaderAction, stopLoaderAction, userAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';

const useAuthentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [nextRoute, setNextRoute] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  // const [isCartEmpty, setIsCartEmpty] = useState<boolean>(true);

  const [apiMessages, setApiMessages] = useState({
    successMessage: '',
    errorMessage: '',
  });

  const [otpState, setOtpState] = useState({
    countdown: 300,
    otp: '',
    isOtpValid: false,
  });

  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const eventDetails = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const { _id } = eventDetails;

  const handleConfirm = async (values: FormValues) => {
    const { mobileNumber: submittedPhoneNumber } = values;
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      if (submittedPhoneNumber) {
        const result = await loginOtp(
          {
            phoneNumber: submittedPhoneNumber.toString(),
            // email: submittedEmail?.toString(),
          },
          true
        );

        if (result.status === statusCodes.API_SUCCESS_CODE) {
          setPhoneNumber(submittedPhoneNumber.toString());
          // setEmail(submittedEmail?.toString());
          setIsOtpModalOpen(true);
          setIsLoginModalOpen(false);
          setOtpState({
            ...otpState,
            countdown: 300,
            otp: '',
          });
          startCountdown();
          setApiMessages({ successMessage: result.message ?? '', errorMessage: '' });
        }
      }
    } catch (error: any) {
      setApiMessages({ errorMessage: error.response.data.message ?? '', successMessage: '' });
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const handleVerify = useCallback(
    async (otp: string) => {
      const loaderRequest = `unique_${Date.now()}`;

      try {
        dispatch(startLoaderAction(loaderRequest));
        const verifyResult = await verifyLoginOtp({ phoneNumber }, otp);
        // const verifyResult = await verifyLoginOtp({ email }, otp);

        if (verifyResult?.status === statusCodes?.API_SUCCESS_CODE) {
          dispatch(userAction());
          setIsOtpModalOpen(false);
          setIsVerified(true);
          setNextRoute(UiRoutes.DELIVERY.split(':id')[0] + _id);
        }
      } catch (error: any) {
        if (error?.response?.data?.errorCode === 'EXCEEDED/MATCH') {
          otpModalProps?.setOtpState({ ...otpModalProps?.otpState, countdown: 0 });
        }

        setApiMessages({ errorMessage: error.response.data.message ?? '', successMessage: '' });
      } finally {
        dispatch(stopLoaderAction(loaderRequest));
      }
    },
    [phoneNumber, apiMessages, nextRoute]
    // [email, apiMessages]
  );

  // const clearUserSession = () => {
  //   dispatch(clearEventDetails());
  //   dispatch(clearUserData());
  //   dispatch(clearInvoiceDetails());
  //   dispatch(clearJourney());
  // };

  const handleResendClick = async () => {
    try {
      clearInterval(countdownIntervalRef.current as NodeJS.Timeout);

      const data = await loginOtp(
        {
          phoneNumber,
        },
        true
      );

      // const data = await loginOtp(
      //   {
      //     email,
      //   },
      //   true
      // );

      if (data.status === statusCodes.API_SUCCESS_CODE) {
        setOtpState({
          ...otpState,
          countdown: 300,
          otp: '',
        });
        startCountdown();
        setApiMessages({ ...apiMessages, successMessage: data.message, errorMessage: '' });
      }
    } catch (error: any) {
      setApiMessages({ ...apiMessages, errorMessage: error.response.data.message, successMessage: '' });
    }
  };

  const startCountdown = () => {
    countdownIntervalRef.current = setInterval(() => {
      setOtpState(prevState => {
        const newCountdown = prevState.countdown - 1;

        if (newCountdown <= 0) {
          clearInterval(countdownIntervalRef.current as NodeJS.Timeout);
          return { ...prevState, countdown: 0 };
        }

        return { ...prevState, countdown: newCountdown };
      });
    }, 1000);
  };

  const loginModalProps = {
    isOpen: isLoginModalOpen,
    onRequestClose: () => setIsLoginModalOpen(false),
    onSave: handleConfirm,
    message:
      'Before we proceed, could you please confirm your mobile number? We’ll send you a One-Time Password (OTP) to ensure it’s really you and to help secure your transaction.',
    heading: 'Confirm Your Mobile Number',
    // message:
    //   'Before we proceed, could you please confirm your email? We’ll send you a One-Time Password (OTP) to ensure it’s really you and to help secure your transaction.',
    // heading: 'Confirm Your Email',
    submitButtonText: 'Confirm',
    apiMessages: apiMessages,
    onSubmitClose: false,
  };

  const headingOutModalProps = {
    isOpen: isLoginModalOpen,
    onRequestClose: () => setIsLoginModalOpen(false),
    onSave: handleConfirm,
    message: SAVE_LATER_MODAL_DESC,
    // message: SAVE_LATER_MODAL_EMAIL_DESC,
    heading: SAVE_LATER_MODAL_HEADING,
    submitButtonText: 'Save & Exit',
    onSubmitClose: false,
    apiMessages: apiMessages,
    cancelButtonText: 'Exit',
    handleCancel: () => {
      setIsLoginModalOpen(false);
      window.location.href = WORDPRESS_BASE_URL ?? '#';
    },
  };

  const otpModalProps = {
    isOpen: isOtpModalOpen,
    onRequestClose: () => setIsOtpModalOpen(false),
    onSave: handleVerify,
    message: `We’ve sent a One-Time Password (OTP) to your mobile number ending with ***${phoneNumber.slice(
      -3
    )}. Please enter the OTP below to verify your identity and continue the checkout process.`,
    heading: 'Verify Your Mobile Number',
    // heading: 'Verify Your Email',
    // message: `We’ve sent a One-Time Password (OTP) to your email at ${obscureInput(
    //   email
    // )}. Please enter the OTP below to verify your identity and continue the checkout process.`,
    submitButtonText: 'Verify',
    handleResend: handleResendClick,
    otpState,
    setOtpState,
    apiMessages: apiMessages,
  };

  return {
    isLoginModalOpen,
    setIsLoginModalOpen,
    isOtpModalOpen,
    setIsOtpModalOpen,
    phoneNumber,
    setPhoneNumber,
    setApiMessages,
    apiMessages,
    email,
    setEmail,
    nextRoute,
    setNextRoute,
    otpState,
    setOtpState,
    dispatch,
    handleConfirm,
    handleVerify,
    handleResendClick,
    startCountdown,
    loginModalProps,
    otpModalProps,
    headingOutModalProps,
    isVerified,
    setIsVerified,
    // isCartEmpty,
    // setIsCartEmpty,
    // authenticateUser,
  };
};

export default useAuthentication;
