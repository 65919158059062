import ActionConstants from '../constants';
export interface HeadingOut {
  inOrderJourney: boolean;
  items: {
    [key: string]: number[];
  };
}
export const inOrderJourney = () => {
  return {
    type: ActionConstants.IN_ORDER_JOURNEY,
  };
};

export const notInOrderJourney = () => {
  return {
    type: ActionConstants.NOT_IN_ORDER_JOURNEY,
  };
};

export const addItem = (id: string, item: number) => {
  return {
    type: ActionConstants.ADD_ITEM,
    payload: { id, item },
  };
};

export const clearJourney = () => {
  return {
    type: ActionConstants.CLEAR_JOURNEY,
  };
};
