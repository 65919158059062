export interface DataProps {
  filter?: Record<string, string>;
  set?: Record<string, unknown>;
  unset?: Record<string, unknown>;
  options?: Record<string, unknown>;
  pull?: Record<string, unknown>;
  push?: Record<string, unknown>;
}

export interface UpdateBodyParams {
  id?: string;
  setData?: Record<string, any | any[]>;
  unsetData?: Record<string, any>;
  options?: Record<string, any>;
  pullData?: Record<string, any>;
  pushData?: Record<string, any>;
}

export interface DeleteBodyParams {
  ids: string | string[];
  options?: Record<string, any>;
}

export interface DeleteBodySchema {
  filter: {
    ids: string[];
  };
  options?: Record<string, any>;
}

export const getUpdateBody = ({ id, setData, unsetData, options, pullData, pushData }: UpdateBodyParams): DataProps => {
  const outputJson: DataProps = {
    ...(id && { filter: { _id: id } }),
    ...(setData && { set: setData }),
    ...(unsetData && { unset: unsetData }),
    ...(options && { options }),
    ...(pullData && { pull: pullData }),
    ...(pushData && { push: pushData }),
  };

  return outputJson;
};

export const deleteBody = ({ ids, options }: DeleteBodyParams): DeleteBodySchema => {
  const outputJson: DeleteBodySchema = {
    filter: { ids: Array.isArray(ids) ? ids : [ids] },
    ...(options && { options }),
  };

  return outputJson;
};
