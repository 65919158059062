import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { createBooking, updateBookingById } from '@src/api/booking';
import { saveUser } from '@src/api/login';
import { deletePackagesEvent } from '@src/api/package';
import useAuthentication from '@src/hooks/useAuthentication';
import {
  DROP_OFF,
  loggedInHeaderTabs,
  menuTabOptions,
  moreItems,
  oneTimePayHeaderTabs,
  statusCodes,
  tabs,
  UiRoutes,
  WORDPRESS_BASE_URL,
  wordpressRoutes,
} from '@src/lib/constants';
import { getUpdateBody } from '@src/lib/getUpdate';
import { dateFormatter, getDishPrice, getDishQuantity, getTotalAmount } from '@src/lib/helper';
import { blastLogo, toggleNavbar } from '@src/lib/imgUrl';
import { Box } from '@src/model/Box';
import { Booking, CreateBookingProps } from '@src/model/Event';
import { BoxPayload, WeeklyAddonPayload, WeeklyDishPayload } from '@src/model/weeklyMenu';
import { clearBookingAction, updateBookingAction } from '@src/redux/action/bookingDetails';
import { clearEventDetails } from '@src/redux/action/eventDetails';
import { clearInvoiceDetails } from '@src/redux/action/invoiceDetails';
import { clearJourney } from '@src/redux/action/journeyStatus';
import { clearUserData, logoutAction, startLoaderAction, stopLoaderAction, userAction } from '@src/redux/action/login';
import { LoginStateProps, ReduxProps } from '@src/redux/type';

import DeleteModal from '../DeleteModal';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';
import OtpVerificationModal from '../Modals/OtpVerificationModal';
import SaveProgressModal from '../Modals/SaveProgressModal';
import PromotionalBanner from '../PromotionalBanner';

import ProfileComponent from './profileComponent';
import {
  DefaultProfileIcon,
  GlobalStyle,
  StyledBlastLogoContainer,
  StyledCloseButton,
  StyledContact,
  StyledCustomLink,
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledDropdownMenuWrapper,
  StyledHamburgerIcon,
  StyledHamburgerImage,
  StyledHeaderContainer,
  StyledImage,
  StyledLoginButton,
  StyledLoginButtonContainer,
  StyledMenuOptionsDropdown,
  StyledMoreButton,
  StyledMoreOptionsDropdown,
  StyledMoreOptionsItem,
  StyledNavTabs,
  StyledOuter,
} from './styles';

interface TabItemProps {
  text: string;
  link: string;
  handleClick: (link: string) => void;
}

const TabItem: React.FC<TabItemProps> = ({ text, link, handleClick }) => {
  return (
    <StyledCustomLink onClick={() => handleClick(link)} key={text}>
      {text}
    </StyledCustomLink>
  );
};

// eslint-disable-next-line complexity
const Header = ({
  isSticky,
  isLoggedIn,
  headerBackGround,
  oneTimePay,
}: {
  isSticky?: boolean;
  isLoggedIn?: boolean;
  headerBackGround?: string;
  oneTimePay?: boolean;
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [showMenus, setShowMenus] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { inOrderJourney } = useSelector((state: ReduxProps) => state?.journeyStatusReducer);
  const [showModal, setShowModal] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [isLogout, setIsLogout] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(true);

  const params = useSearchParams();
  const _eventId = params[0].get('eventId');

  const {
    firstName: userFirstName,
    isUserAuthenticated,
    phoneNumber: userPhoneNumber,
    //TODO: need when login with email
  } = useSelector((state: { loginReducer: { loginState: LoginStateProps } }) => state?.loginReducer?.loginState);

  const toggleMenuVisible = () => setMenuVisible(prev => !prev);
  const toggleShowMore = () => setShowMore(prev => !prev);
  const toggleMenus = () => setShowMenus(prev => !prev);

  const {
    setIsLoginModalOpen,
    isLoginModalOpen,
    isOtpModalOpen,
    otpModalProps,
    headingOutModalProps,
    phoneNumber,
    apiMessages,
    setApiMessages,
  } = useAuthentication();

  const defaultBooking = useSelector((state: ReduxProps) => state?.bookingDetailsReducer?.booking);

  const serviceType = DROP_OFF;

  const { _id, makeMyOwn, packageDetails, Bookings } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const dropOffEditMode = packageDetails?.service === DROP_OFF && !!_eventId;

  const getBookingTotal = () => {
    const currentBooking =
      defaultBooking?.length > 0 ? defaultBooking : Bookings && Bookings?.length > 0 ? Bookings : [];

    if (packageDetails.service === DROP_OFF) {
      return getTotalAmount(currentBooking, true);
    }

    return getTotalAmount(defaultBooking, makeMyOwn);
  };

  const bookingTotal = getBookingTotal();

  useEffect(() => {
    const hasDishes = defaultBooking?.[0]?.dishes?.length > 0 || (Bookings && Bookings?.[0]?.dishes?.length > 0);
    const hasBoxes = defaultBooking?.[0]?.boxes?.length > 0 || (Bookings && Bookings?.[0]?.boxes?.length > 0);
    const hasTotalAmount = (defaultBooking?.[0]?.totalAmount ?? 0) > 0 || (Bookings?.[0]?.totalAmount ?? 0) > 0;

    const hasBookingTotal = bookingTotal > 0;

    if ((defaultBooking && (hasDishes || hasTotalAmount)) || hasBoxes || hasBookingTotal) {
      setIsCartEmpty(false);
    } else {
      setIsCartEmpty(true);
    }
  }, [defaultBooking, bookingTotal]);

  const handleBookingUpdate = async () => {
    const loaderRequest = `unique_${Date.now()}`;
    const bookingId = Bookings && Bookings.length > 0 ? Bookings?.[0]?._id : _id;

    const selectedBooking = dropOffEditMode
      ? defaultBooking?.length > 0
        ? defaultBooking[0]
        : Bookings?.[0]
      : defaultBooking?.length > 0
      ? defaultBooking[0]
      : ({} as Booking);

    const dishes: WeeklyDishPayload[] = [];
    const addOns: WeeklyAddonPayload[] = [];
    const boxes: BoxPayload[] = [];

    try {
      dispatch(startLoaderAction(loaderRequest));
      selectedBooking?.addOns?.forEach(addon => {
        addOns.push({
          category: addon?.category?._id as string,
          includedInPackage: makeMyOwn ? false : !!addon?.includedInPackage,
          itemCount: addon?.itemCount || 1,
          name: addon?.name,
          price: addon?.price,
          quantity: addon?.quantity,
          addOnId: addon?.addOnId,
        });
      });
      selectedBooking?.dishes?.forEach(dish => {
        dishes.push({
          category: dish?.category?._id as string,
          includedInPackage: makeMyOwn ? false : !!dish?.includedInPackage,
          itemCount: dish?.itemCount || (1 as number),
          name: dish?.name,
          dishId: dish?.dishId,
          price: getDishPrice(dish, serviceType),
          quantity: getDishQuantity(dish, serviceType),
        });
      });

      if (dropOffEditMode) {
        selectedBooking?.boxes?.forEach(box => {
          const boxId = box.boxId as Box;
          boxes.push({
            itemCount: box?.itemCount || (1 as number),
            name: boxId?.name as string,
            price: boxId?.price,
            boxId: boxId?._id as string,
            sapId: boxId?.sapId as string,
            quantity: 1,
          });
        });
      } else {
        selectedBooking?.boxes?.forEach(box => {
          boxes.push({
            itemCount: box?.itemCount || (1 as number),
            name: box?.name as string,
            price: box?.price,
            boxId: box?._id as string,
            sapId: box?.sapId as string,
            quantity: 1,
          });
        });
      }

      const newBooking = {
        eventId: _id,
        dishes,
        boxes,
        bookingDate: dateFormatter(new Date().toISOString()),
        extra: false,
        packageDetails: {
          name: 'BUILD MY OWN',
          packageId: makeMyOwn ? undefined : packageDetails?.packageId,
          service: packageDetails?.service,
          type: 'lunch',
        },
        makeMyOwn: makeMyOwn,
        bookingTime: new Date().toISOString(),
      } as CreateBookingProps;

      if (!dropOffEditMode && packageDetails?.service === DROP_OFF) {
        return await createBooking(newBooking)
          .then(async res => {
            dispatch(updateBookingAction([...(defaultBooking as Booking[])]));
          })
          .catch(error => error);
      } else {
        return await updateBookingById(
          bookingId as string,
          getUpdateBody({
            setData: {
              dishes,
              ...(addOns?.length ? { addOns: addOns } : { addOns: [] }),
              ...(boxes?.length ? { boxes: boxes } : { boxes: [] }),
            },
          })
        ).catch(error => error);
      }
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const handleDeleteEvent = async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      _id && (await deletePackagesEvent(getUpdateBody({ id: _id })));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const handleHeadingOutVerify = useCallback(
    async (otp?: string) => {
      const loaderRequest = `unique_${Date.now()}`;

      try {
        dispatch(startLoaderAction(loaderRequest));

        if (isCartEmpty) {
          await handleDeleteEvent();
          return;
        }

        const headingOutData = {
          ...(otp ? { otp: otp, phoneNumber } : { phoneNumber: userPhoneNumber }),
          // ...(otp ? { otp: otp, email } : { email: userEmail }),
          //TODO: need for email login flow
          orderUrl: process.env.REACT_APP_BASE_URL + UiRoutes.ORDER_DETAILS.replace(':eventId', _id ?? ''),
          eventId: _id,
        };

        packageDetails?.service === DROP_OFF && (await handleBookingUpdate());
        const verifyResult = await saveUser(headingOutData);

        if (verifyResult?.status === statusCodes?.API_SUCCESS_CODE) {
          if (otp) {
            enqueueSnackbar(
              'Your order has been saved successfully! You can access your order anytime by logging in with your mobile number.',
              {
                variant: 'success',
                autoHideDuration: 3000,
                onClose: () => {
                  window.location.href = redirectLink ?? WORDPRESS_BASE_URL ?? '#';
                },
              }
            );
          } else {
            window.location.href = redirectLink ?? WORDPRESS_BASE_URL ?? '#';
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.errorCode === 'EXCEEDED/MATCH') {
          otpModalProps?.setOtpState({ ...otpModalProps?.otpState, countdown: 0 });
        }

        setApiMessages({ ...apiMessages, errorMessage: error.response.data.message, successMessage: '' });
      } finally {
        dispatch(stopLoaderAction(loaderRequest));
      }
    },
    // [email, _id, isUserAuthenticated, redirectLink, WORDPRESS_BASE_URL, userEmail, apiMessages]
    [phoneNumber, _id, isUserAuthenticated, redirectLink, WORDPRESS_BASE_URL, userPhoneNumber, apiMessages]
    //TODO: need for email login flow
  );

  const headingOutOtpProps = {
    ...otpModalProps,
    onSave: handleHeadingOutVerify,
    message: `We’ve sent a One-Time Password (OTP) to your mobile number ending with  ***${phoneNumber.slice(
      -3
    )}. Please input the OTP provided to verify your identity and proceed with saving for later.`,
    // message: `We’ve sent a One-Time Password (OTP) to your email at ${obscureInput(
    //   email
    // )}. Please input the OTP provided to verify your identity and proceed with saving for later.`,
  };

  const handleEventClearing = async (link?: string) => {
    dispatch(clearEventDetails());
    dispatch(clearInvoiceDetails());
    dispatch(clearJourney());
    await handleDeleteEvent();
    window.location.href = link ?? '#';
  };

  const handleClick = (link: string) => {
    if (inOrderJourney) {
      setRedirectLink(link);

      if (isCartEmpty && !dropOffEditMode) {
        handleEventClearing(link);
      } else if (!isCartEmpty && dropOffEditMode) {
        setShowModal(true);
      } else if (!isCartEmpty && !dropOffEditMode) {
        if (isUserAuthenticated) {
          setShowModal(true);
        } else {
          setIsLoginModalOpen(true);
        }
      } else {
        window.location.href = link ?? '#';
      }
    } else {
      window.location.href = link ?? '#';
    }
  };

  const handleEditModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isLoginModalOpen || isOtpModalOpen) {
      setApiMessages({
        successMessage: '',
        errorMessage: '',
      });
    }
  }, [isLoginModalOpen, isOtpModalOpen]);

  useEffect(() => {
    const bodyClass = 'activeDropdown';

    if (menuVisible) {
      document.body.classList.add(bodyClass);
    } else {
      document.body.classList.remove(bodyClass);
    }

    return () => document.body.classList.remove(bodyClass);
  }, [menuVisible]);

  const renderLoggedInTabs = () =>
    loggedInHeaderTabs.map(tab => (
      <TabItem key={tab.text} text={tab.text} link={tab.link || '#'} handleClick={handleClick} />
    ));

  const renderOneTimePayTabs = () =>
    oneTimePayHeaderTabs.map(tab => (
      <TabItem key={tab.text} text={tab.text} link={tab.link || '#'} handleClick={handleClick} />
    ));

  const renderTabs = () =>
    tabs.map(tab => <TabItem key={tab.text} text={tab.text} link={tab.link || '#'} handleClick={handleClick} />);

  const renderMenuTypeTabs = () =>
    menuTabOptions.map(({ text, link }) => (
      <StyledCustomLink onClick={() => handleClick(link)} key={text}>
        <StyledMoreOptionsItem>{text}</StyledMoreOptionsItem>
      </StyledCustomLink>
    ));

  const renderMoreItems = () =>
    moreItems.map(({ text, link }) => (
      <StyledCustomLink onClick={() => handleClick(link)} key={text}>
        <StyledMoreOptionsItem>{text}</StyledMoreOptionsItem>
      </StyledCustomLink>
    ));

  const renderMenuIcon = () =>
    isLoggedIn ? (
      <DefaultProfileIcon>{userFirstName?.charAt(0) ?? 'G'}</DefaultProfileIcon>
    ) : (
      <StyledHamburgerImage src={toggleNavbar} alt='Toggle Navbar' />
    );

  const performLogoutActions = () => {
    dispatch(clearEventDetails());
    dispatch(logoutAction());
    dispatch(clearUserData());
    dispatch(clearInvoiceDetails());
    dispatch(clearJourney());
  };

  const handleLogoutClick = async (link: string) => {
    if (inOrderJourney && !isCartEmpty) {
      setRedirectLink(link);
      setIsLoginModalOpen(true);
    } else {
      await handleDeleteEvent();
      dispatch(clearEventDetails());
      dispatch(clearUserData());
      dispatch(clearInvoiceDetails());
      dispatch(clearJourney());
      window.location.href = link ?? '#';
    }
  };

  const handleLogout = () => {
    setIsLogout(true);

    if (inOrderJourney) {
      setShowModal(true);
    } else {
      performLogoutActions();
      // navigate(UiRoutes.HOME);
      navigate(UiRoutes.PACKAGESFS);
    }
  };

  return (
    <>
      <StyledOuter $isSticky={isSticky}>
        <GlobalStyle />
        {!oneTimePay && <PromotionalBanner />}
        <StyledHeaderContainer $headerBackGround={headerBackGround}>
          <MaxWidthContainer extraStyle={{ justifyContent: 'space-between' }}>
            <StyledBlastLogoContainer>
              <StyledCustomLink onClick={() => handleClick(WORDPRESS_BASE_URL ?? UiRoutes.PACKAGESFS)}>
                <StyledImage src={blastLogo} alt='Blast Logo' />
              </StyledCustomLink>

              {isLoggedIn && (
                <StyledNavTabs
                  onMouseLeave={() => {
                    setShowMenus(true);
                  }}
                >
                  {oneTimePay ? renderOneTimePayTabs() : renderLoggedInTabs()}
                  <StyledDropdownMenuItem>
                    <StyledMoreButton show={showMenus} onClick={toggleMenus}>
                      Menu {showMenus ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </StyledMoreButton>
                    {!showMenus && <StyledMoreOptionsDropdown>{renderMenuTypeTabs()}</StyledMoreOptionsDropdown>}
                  </StyledDropdownMenuItem>
                </StyledNavTabs>
              )}
            </StyledBlastLogoContainer>
            <StyledHamburgerIcon onClick={toggleMenuVisible}>
              {menuVisible ? <StyledCloseButton>X</StyledCloseButton> : renderMenuIcon()}
            </StyledHamburgerIcon>
            {!isLoggedIn && (
              <StyledNavTabs
                onMouseLeave={() => {
                  setShowMore(true);
                  setShowMenus(true);
                }}
              >
                {renderTabs()}
                <StyledDropdownMenuItem>
                  <StyledMoreButton show={showMenus} onClick={toggleMenus}>
                    Menu {showMenus ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </StyledMoreButton>
                  {!showMenus && (
                    <StyledMenuOptionsDropdown style={{ textAlign: 'right', overflow: 'hidden' }}>
                      {renderMenuTypeTabs()}
                    </StyledMenuOptionsDropdown>
                  )}
                </StyledDropdownMenuItem>

                <StyledDropdownMenuItem>
                  <StyledMoreButton show={showMore} onClick={toggleShowMore}>
                    More {showMore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                  </StyledMoreButton>
                  {!showMore && <StyledMoreOptionsDropdown>{renderMoreItems()}</StyledMoreOptionsDropdown>}
                </StyledDropdownMenuItem>
              </StyledNavTabs>
            )}

            {isLoggedIn ? (
              !oneTimePay ? (
                <ProfileComponent />
              ) : (
                <></>
              )
            ) : (
              <StyledLoginButtonContainer>
                <StyledContact
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'click_contact',
                    });
                    window.location.href = WORDPRESS_BASE_URL + wordpressRoutes.CONTACT_US;
                  }}
                >
                  Contact Us
                </StyledContact>
                <StyledLoginButton onClick={() => handleLogoutClick(UiRoutes.LOGIN)}>Login</StyledLoginButton>
              </StyledLoginButtonContainer>
            )}
            {menuVisible && (
              <StyledDropdownMenuWrapper isVisible={menuVisible}>
                <StyledDropdownMenu>
                  {isLoggedIn ? (
                    <>
                      {renderLoggedInTabs()}
                      <StyledDropdownMenuItem>
                        <StyledMoreButton show={showMenus} onClick={toggleMenus}>
                          Menu {showMenus ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </StyledMoreButton>
                        {!showMenus && <StyledMoreOptionsDropdown>{renderMenuTypeTabs()}</StyledMoreOptionsDropdown>}
                      </StyledDropdownMenuItem>
                      <StyledLoginButton onClick={() => handleLogout()} $mobileView>
                        Logout
                      </StyledLoginButton>
                    </>
                  ) : (
                    <>
                      {renderTabs()}
                      <StyledDropdownMenuItem>
                        <StyledMoreButton show={showMenus} onClick={toggleMenus}>
                          Menu {showMenus ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </StyledMoreButton>
                        {!showMenus && <StyledMenuOptionsDropdown>{renderMenuTypeTabs()}</StyledMenuOptionsDropdown>}
                      </StyledDropdownMenuItem>
                      <StyledDropdownMenuItem>
                        <StyledMoreButton show={showMore} onClick={toggleShowMore}>
                          More {showMore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </StyledMoreButton>
                        {!showMore && <StyledMoreOptionsDropdown>{renderMoreItems()}</StyledMoreOptionsDropdown>}
                      </StyledDropdownMenuItem>

                      <StyledLoginButton onClick={() => handleLogoutClick(UiRoutes.LOGIN)} $mobileView>
                        Login
                      </StyledLoginButton>
                    </>
                  )}
                </StyledDropdownMenu>
              </StyledDropdownMenuWrapper>
            )}
          </MaxWidthContainer>
        </StyledHeaderContainer>
      </StyledOuter>
      <OtpVerificationModal {...headingOutOtpProps} />
      {isUserAuthenticated ? (
        <DeleteModal
          isOpen={showModal}
          modalName='Are you sure you want to exit? Your progress will be saved.'
          onRequestClose={handleEditModalClose}
          onSave={handleHeadingOutVerify}
          isLogoutAction={isLogout}
          performLogoutActions={performLogoutActions}
        />
      ) : (
        <SaveProgressModal {...headingOutModalProps} />
      )}
    </>
  );
};

export default Header;
