import { DateTime } from 'luxon';
import moment from 'moment';

import { Category } from '@src/model/Category';
import { Booking, Dish, Service } from '@src/model/Event';
import { ResponseProps } from '@src/model/GlobalTypes';
import { Package } from '@src/model/packages';
import { Settings } from '@src/model/Settings';
import { MealType } from '@src/model/weeklyMenu';
import { orderSummaryStatus } from '@src/screens/OrderHistory';
import { theme } from '@src/styles/theme';

import { ADDONS_CATEGORY, BUFFET, DEFAULT_SERVICE_TYPE, DROP_OFF, StatusType, stepperStatus, steps } from './constants';
import {
  appetizers,
  bakeryBites,
  celeryIcon,
  crustaceansIcon,
  defaultImage,
  desserts,
  dinner,
  eggsIcon,
  fishIcon,
  hotMain,
  hotSide,
  lupinIcon,
  milkIcon,
  mustardIcon,
  package1,
  package2,
  package3,
  peanutsIcon,
  sesameseedsIcon,
  soyaIcon,
} from './imgUrl';
import { permissionEnum } from './types';

export const checkPermissions = (permission: permissionEnum): boolean => {
  return permission ? true : false;
};

export const filterNumeric = (input: string) => {
  return input.replace(/[^\d]/g, '');
};

export function cleanPhoneNumber(input: string) {
  return input.replace(/(?!^\+)\D/g, '');
}

export function obscureInput(input: string) {
  if (input.includes('@')) {
    const [username, domain] = input.split('@');

    if (username.length > 2) {
      const obscuredUsername = username.substring(0, 3) + '*'.repeat(username.length - 3);
      return `${obscuredUsername}@${domain}`;
    } else {
      return input;
    }
  } else {
    const length = input?.split('+971')[1]?.length;

    if (length > 2) {
      const obscuredNumber = input.split('+971')[1].substring(0, 3) + '*'.repeat(length - 3);
      return obscuredNumber;
    } else {
      return input;
    }
  }
}

export function formatDate(dateString: string | Date, dateFormat?: string): string {
  const date = moment(dateString);
  const formattedDate = date.format(dateFormat || 'D MMMM, YY');

  return formattedDate;
}

export function groupByBookingDateAndTime(
  dataArray: Booking[],
  startDate: string,
  endDate: string
): Record<string, Booking[]> {
  const groupedData: Record<string, Booking[]> = {};

  let currentDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  const eventDate = currentDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
  groupedData[eventDate] = [];

  while (currentDate.diff(lastDate) <= 0) {
    const formattedDate = currentDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
    groupedData[formattedDate] = [];
    currentDate = currentDate.add(1, 'day');
  }

  dataArray?.forEach(item => {
    const bookingDate = moment(item.bookingDate).startOf('day');
    const formattedBookingDate = bookingDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';

    if (Object.prototype.hasOwnProperty.call(groupedData, formattedBookingDate)) {
      groupedData[formattedBookingDate].push(item);
    } else if (item.packageDetails?.service === DROP_OFF) {
      groupedData[eventDate].push(item);
    }
  });

  return groupedData;
}

export const getBookingPackageType = ({
  allBookings,
  type,
  notType,
}: {
  type?: string;
  allBookings?: Booking[];
  notType?: string[];
}) => {
  if (type) {
    return allBookings?.filter(item => item?.packageDetails?.type === type);
  } else {
    return allBookings?.filter(item =>
      item?.packageDetails?.type ? !notType?.includes(item?.packageDetails?.type) : false
    );
  }
};

export const getCategoryDish = ({ category, dishes }: { dishes: Dish[]; category: string }) => {
  return dishes?.filter(dish => dish?.category?.name === category);
};

export const getPackageColor = (type: string) => {
  switch (type) {
    case 'breakfast':
      return {
        border: theme.primaryColor.powderBlue,
        bgColor: theme.primaryColor.aliceBlue,
        buttonColor: theme.primaryColor.shadePurple,
      };
    case 'lunch':
      return {
        border: theme.primaryColor.coral,
        bgColor: theme.primaryColor.creamColor,
        buttonColor: theme.primaryColor.darkYellow,
      };
    case 'break':
      return {
        border: theme.primaryColor.powderBlue,
        bgColor: theme.primaryColor.aqua,
        buttonColor: theme.primaryColor.cyanGreen,
      };
    default:
      return {
        border: theme.primaryColor.lunarElegance,
        bgColor: theme.primaryColor.snow,
        buttonColor: theme.primaryColor.mutedGray,
      };
  }
};

export function capitalizeFirstLetter(name?: string) {
  if (!name) return name;

  if (name.includes('lunch')) {
    return `Lunch/Dinner${name.split('lunch')?.[1] ? name.split('lunch')?.[1] : ''}`;
  }

  if (name.includes('_')) {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}

export function formatDates(startDate: string, endDate: string) {
  if (startDate === '') {
    return '';
  }

  if (startDate === undefined) {
    return '-';
  }

  function getDate(date: Date) {
    const day = new Date(date).getDate();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()];
    return `${day} ${month}`;
  }

  const formattedStartDate = getDate(new Date(startDate));
  const formattedEndDate = getDate(new Date(endDate));

  const year = new Date(endDate).getFullYear().toString().slice(-2);

  if (formattedEndDate === formattedStartDate) {
    return formatDate(new Date(startDate));
  } else {
    return `${formattedStartDate} - ${formattedEndDate}, ${year}`;
  }
}

export const capitalizeFirstLetterOnly = (name?: string) => {
  if (!name) return '';

  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getTotalAmount = (bookings: Booking[], isMakeMyOwn?: boolean) => {
  if (!Array.isArray(bookings) || bookings.length === 0) {
    return 0;
  }

  const totalAmount = bookings.reduce((acc, booking) => {
    if (booking && booking?.packageDetails && Array.isArray(booking?.dishes)) {
      const serviceType = booking?.packageDetails?.service ?? DEFAULT_SERVICE_TYPE;

      const bookingAmount =
        (booking?.numberOfPerson || 0) * (booking?.packageDetails?.price || 0) +
        booking?.dishes
          .filter(dish => dish && (!dish?.includedInPackage || isMakeMyOwn))
          .reduce(
            (dishAcc, dish) =>
              dishAcc + (dish?.itemCount || 0) * ((serviceType === BUFFET ? dish?.price : dish?.dropOffPrice) || 0),
            0
          ) +
        (booking?.addOns
          ?.filter(addon => addon && (!addon?.includedInPackage || isMakeMyOwn))
          ?.reduce((addonAcc, addon) => addonAcc + ((addon?.itemCount || 0) * addon?.price || 0), 0) || 0) +
        (booking?.boxes?.reduce((boxAcc, box) => boxAcc + (box?.itemCount || 0) * box?.price, 0) || 0);

      return acc + bookingAmount;
    }

    return acc;
  }, 0);

  return totalAmount;
};

export function calculatePercentage(amount: number, percentage?: number): number {
  return percentage ? (amount * percentage) / 100 : 0;
}

export const getFirstLastName = (name: string) => {
  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  const result: Record<string, string> = {};
  result['firstName'] = firstName;

  if (lastName) result['lastName'] = lastName;

  return result;
};

export const dateFormatter = (dateStr: string, addDays?: number) => {
  const parsedDate = moment(dateStr).add(addDays ?? 0, 'days');

  return parsedDate.format('YYYY-MM-DD');
};

export function formatNumber(num: number | string, fix?: boolean) {
  const parsedNumber = typeof num === 'string' ? parseFloat(num) : num;

  if (isNaN(parsedNumber)) {
    return num;
  }

  let formattedNumber: string;

  if (fix) {
    formattedNumber = parsedNumber.toFixed(2);
  } else {
    formattedNumber = parsedNumber.toString();
  }

  const parts = formattedNumber.split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  formattedNumber = fix ? parts.join('.') : parts[0];

  return formattedNumber;
}

export const dateFormat = (date: string | undefined) => {
  const parsedDate = moment(date);

  return parsedDate.format('DD-MM-YYYY');
};

export function formatAmount(number: number): string {
  let formattedNumber = 'AED ' + parseFloat(number?.toFixed(2)).toLocaleString('en-US');

  if (!/\./.test(formattedNumber)) {
    formattedNumber += '.00';
  }

  return formattedNumber;
}
export const removeDuplicates = (arr: string[]) => {
  return arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

const removeSpacesAndLowerCase = (inputString: string) => {
  return inputString?.toLowerCase().replace(/\s+/g, '');
};

export const getCategoryIcon = (category: string) => {
  const categoryType = removeSpacesAndLowerCase(category);

  switch (categoryType) {
    case 'appetizer/lightbite':
      return appetizers;
    case 'bakerybite':
      return bakeryBites;
    case 'dessert':
      return desserts;
    case 'hotmain':
      return hotMain;
    case 'hotside':
      return hotSide;
    default:
      return dinner;
  }
};

export const getPackageIcon = (index: number | undefined) => {
  switch (index) {
    case 0:
      return package1;
    case 1:
      return package2;
    case 2:
      return package3;
    default:
      return defaultImage;
  }
};

export const getAllergensIcon = (allergens: string) => {
  const allergensType = removeSpacesAndLowerCase(allergens);

  const allergensList = [
    { name: 'celery', icon: celeryIcon },
    { name: 'bakerybite', icon: crustaceansIcon },
    { name: 'eggs', icon: eggsIcon },
    { name: 'fish', icon: fishIcon },
    { name: 'lupin', icon: lupinIcon },
    { name: 'milk', icon: milkIcon },
    { name: 'mustard', icon: mustardIcon },
    { name: 'soya', icon: soyaIcon },
    { name: 'sesameseeds', icon: sesameseedsIcon },
    { name: 'peanuts', icon: peanutsIcon },
  ];

  const getSimilarityScore = (input: string, target: string) => {
    let score = 0;

    if (input)
      for (const char of input) {
        if (target?.includes(char)) {
          score++;
        }
      }

    return score / target?.length;
  };

  let bestMatchIcon = '';
  let highestScore = 0;

  for (const { name, icon } of allergensList) {
    const score = getSimilarityScore(allergensType, name);

    if (score > highestScore) {
      highestScore = score;
      bestMatchIcon = icon;
    }
  }

  const SIMILARITY_THRESHOLD = 0.7;

  if (bestMatchIcon && highestScore >= SIMILARITY_THRESHOLD) {
    return bestMatchIcon;
  } else {
    return celeryIcon;
  }
};

export const getDropDownOptions = ({
  bookings,
  makeMyOwn,
  selectedValue,
  selectedDate,
}: {
  bookings: Booking[];
  makeMyOwn?: boolean;
  selectedDate?: string;
  selectedValue?: string;
}): Record<string, string>[] => {
  const dropdownOptions: Record<string, string>[] = [];
  const packagesType = ['breakfast', 'lunch', 'break'];

  packagesType.map(type => {
    const bookingByType = getBookingPackageType({ allBookings: bookings, type })?.filter(({ bookingDate }) => {
      return moment(bookingDate).isSame(selectedDate, 'day');
    });

    if (bookingByType?.length) {
      if (bookingByType?.length === 2) {
        bookingByType?.forEach(item => {
          dropdownOptions.push({
            name:
              type === 'lunch'
                ? `Lunch/Dinner(${item.extra ? 2 : 1})`
                : `${capitalizeFirstLetter(type)}(${item.extra ? 2 : 1})`,
            key: type,
            id: item?._id as string,
          });
        });
      } else {
        dropdownOptions.push({
          name: type === 'lunch' ? 'Lunch/Dinner' : (capitalizeFirstLetter(type) as string),
          key: type,
          id: bookingByType[0]._id as string,
        });
      }
    }
  });

  if (selectedValue && makeMyOwn && packagesType.includes(selectedValue)) {
    if (!dropdownOptions.some(item => item.key === selectedValue)) {
      dropdownOptions.push({
        name: selectedValue === 'lunch' ? 'Lunch/Dinner' : (capitalizeFirstLetter(selectedValue) as string),
        key: selectedValue,
        id: selectedValue,
      });
    }
  }

  return dropdownOptions;
};

export const checkEditableOrder = (orderDetails: string) => {
  if (orderDetails && orderSummaryStatus.includes(orderDetails as StatusType)) {
    return false;
  }

  return true;
};

export const getMinimumCount = (packageData?: Package[]) => {
  const result: (number | null)[] = [null, null];

  if (!packageData) return result;

  for (const data of packageData) {
    if (!data.minimumValue) continue;

    if (result[0] === null && data.service === BUFFET) {
      result[0] = data.minimumValue;
    }

    if (result[1] === null && data.service === DROP_OFF) {
      result[1] = data.minimumValue;
    }
  }

  return result;
};

export const getSteps = (
  paymentStatus: string,
  numberOfPerson: number,
  isPaymentRequired: boolean,
  eventStatus: string,
  isContract?: boolean
) => {
  if (stepperStatus?.includes(eventStatus)) {
    return steps.slice(0, 3);
  }

  if (!isPaymentRequired) {
    return [...steps.slice(0, 3), steps[4]];
  }

  if ((numberOfPerson > 150 || isContract) && !isPaymentRequired) {
    return [...steps.slice(0, 3), steps[4]];
  }

  return steps;
};

export const handleRemoveNegativeNumbers = (num: string) => {
  const parsedValue = parseInt(num, 10);

  if (!isNaN(parsedValue) && parsedValue > 0) {
    return parsedValue;
  } else {
    return '';
  }
};

export const getDeliveryCharges = (location: string, settings?: Settings) => {
  const deliveryCharge = settings?.deliveryCharges?.find(item => item?.emirate === location?.toLowerCase())?.charges;

  return deliveryCharge || 0;
};

export const getUniqueRecords = <T extends { _id?: string }>(
  previous: ResponseProps<T> | undefined,
  response: { data: ResponseProps<T> }
) => {
  const { data } = response || {};
  const { page, totalPages, results } = data || {};
  const existingResultIds = new Set(previous?.results?.map(result => result._id));

  const updatedResults = results
    ? [...(previous?.results || []), ...results.filter(newResult => !existingResultIds.has(newResult._id))]
    : previous?.results || [];

  return {
    page: page ?? previous?.page,
    totalPages: totalPages ?? previous?.totalPages,
    results: updatedResults,
  };
};

export const isAddonCategory = (name: string) => {
  return name === ADDONS_CATEGORY;
};

export const getDishPrice = (dish: Dish, serviceType: Service) => {
  return (dish.addOn ? dish?.price : serviceType === BUFFET ? dish?.price : dish?.dropOffPrice) ?? 0;
};

export const getDishQuantity = (dish: Dish, serviceType: Service) => {
  return (serviceType === BUFFET ? dish?.quantity : dish?.dropOffQuantity) ?? 0;
};

/**
 *
 * @param days
 * @param addDays This is only for testing purposes
 * @returns
 */
export const getThresholdDays = (days: number, addDays = 0, startDate?: string) => {
  let newThreshold = days + addDays;

  const currentUTCTime = startDate ? new Date(startDate).toISOString() : new Date().toISOString();

  const currentDubaiTime = DateTime.fromISO(currentUTCTime, {
    zone: 'Asia/Dubai',
  });

  const startDateDubai = currentDubaiTime.plus({ days: addDays });

  const isAfterTwo = startDateDubai.hour >= 14;

  switch (startDateDubai.weekday) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 7:
      newThreshold = isAfterTwo ? newThreshold + 1 : newThreshold;
      break;
    case 5:
      newThreshold = isAfterTwo ? newThreshold + 2 : newThreshold;
      break;
    case 6:
      newThreshold += 1;
      break;
    default:
      break;
  }

  return newThreshold;
};

export const addGoogleTrackingForEvent = (
  eventName: string,
  additionalParams?: { [key: string]: any },
  callback?: () => void
) => {
  window.dataLayer = window.dataLayer || [];

  const eventObject = {
    event: eventName,
    ...additionalParams, // Spread any additional parameters here
  };

  window.dataLayer.push(eventObject);

  // If a callback function is provided, invoke it
  if (callback) {
    callback();
  }
};

// for scroll event
export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getMealTypeIndex = ({ type }: { type: string }) => {
  switch (type) {
    case 'breakfast':
      return 0;
    case 'lunch':
      return 1;
    case 'break':
      return 2;
    // case 'all':
    //   return 0;
    // case 'beverages':
    //   return 3;
    default:
      return 1;
  }
};

export const getMealKeyFromIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'breakfast';
    case 1:
      return 'lunch';

    case 2:
      return 'break';
    case 3:
      return 'beverages';
    // case 4:
    default:
      return 'lunch';
  }
};

export const convertTimeTo12HrFormat = (time: string): string => {
  const [hours, minutes] = time.split(':');
  let period = 'AM';

  let hourNumber = parseInt(hours, 10);

  if (hourNumber >= 12) {
    period = 'PM';

    if (hourNumber > 12) hourNumber -= 12;
  } else if (hourNumber === 0) {
    hourNumber = 12;
  }

  return `${hourNumber}:${minutes} ${period}`;
};

export const getMealLabel = (meal: MealType) => {
  switch (meal) {
    case 'break':
      return 'Break';
    case 'breakfast':
      return 'Breakfast';
    case 'lunch':
      return 'Lunch/Dinner';
    default:
      return 'Breakfast';
  }
};

export const isBoxCategory = (catName: string) => {
  if (catName.toLowerCase() === 'boxes') {
    return true;
  }

  return false;
};

export const BOXES_CATEGORY: Category = {
  name: 'Boxes',
  sequence: 0,
};

export const getDishCategoryFromIndex = (selectedCategory: number) => {
  switch (selectedCategory) {
    case 0:
      return DishCategory.breakfast;
    case 1:
      return DishCategory.lunch;
    case 2:
      return DishCategory.break;

    default:
      return DishCategory.lunch;
  }
};

export const isFestiveCategory = (selectedCategory: number) => {
  if (selectedCategory === 3) {
    return 'Christmas Menu';
  }
};

export enum DishCategory {
  lunch = 'lunch',
  breakfast = 'breakfast',
  break = 'break',
}
