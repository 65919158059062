import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledModalContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.primaryColor.white};
  top: 50%;
  left: 50%;
  width: 35%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  ${Breakpoints.TABLET} {
    top: 50%;
    left: 50%;
    width: 90%;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
`;

export const StyledBody = styled.div`
  height: 40vh;
  display: flex;
`;

export const StyledLeftColumn = styled.div<{ $isDateFilter?: boolean }>`
  flex: 1;
  overflow-x: hidden;
  ${({ $isDateFilter }) => $isDateFilter && Breakpoints.MOBILE} {
    flex: none;
    width: 33%;
  }
  ${({ $isDateFilter }) => ($isDateFilter ? '' : 'overflow-y: auto;')}
  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  }
`;

export const StyledRightColumn = styled.div<{ $isDateFilter?: boolean }>`
  flex: 1;
  ${({ $isDateFilter }) => ($isDateFilter ? '' : 'overflow-y: auto;')}
  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  }
`;
export const StyledFooter = styled.div`
  display: flex;
  justify-content: end;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  gap: 1rem;
`;

export const StyledButton = styled.button<{ $primary?: boolean }>`
  border-radius: 8px;
  padding: 0.6rem 1.4rem;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  background-color: ${props =>
    props.$primary ? props.theme.primaryColor.shadePurple : props.theme.primaryColor.white};
  color: ${props => (props.$primary ? props.theme.primaryColor.white : props.theme.primaryColor.black)};
  cursor: pointer;
`;

export const StyledHeaderText = styled.span`
  cursor: pointer;
`;

export const StyledLabelInput = styled.label<{ $isDateFilter?: boolean }>`
  padding: 1rem;
  display: block;
  cursor: pointer;
  ${({ $isDateFilter }) => $isDateFilter && Breakpoints.MOBILE} {
    padding: 16px 8px;
  }
`;

export const StyledLabel = styled.div`
  padding: 1rem;
`;

export const StyledIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const StyledLableContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.$isSelected ? props.theme.primaryColor.skyBlue : props.theme.primaryColor.white)};
  cursor: pointer;
`;
