import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';

import Modal from '@mui/material/Modal';

import DatePicker from '@src/components/DatePicker';
import { dateFormatter } from '@src/lib/helper';
import { arrowRight } from '@src/lib/imgUrl';

import { Date as DateType } from '../EventDetailModal';

import {
  StyledBody,
  StyledButton,
  StyledFooter,
  StyledHeader,
  StyledHeaderText,
  StyledIcon,
  StyledLabel,
  StyledLabelInput,
  StyledLableContainer,
  StyledLeftColumn,
  StyledModalContainer,
  StyledRightColumn,
} from './style';

export interface FilterSelectedOptions {
  label: string;
  value: string | boolean;
}

export interface FilterOptions {
  label: string;
  key: string;
  value: FilterSelectedOptions[];
  isMulti?: boolean;
  isDate?: boolean;
}

export interface FilterSelectOptions {
  [key: string]: string[] | boolean[] | (string | boolean)[] | string | boolean;
}

interface FilterProps {
  isOpen: boolean;
  onClose: () => void;
  handleApply: () => void;
  filterData: FilterOptions[];
  filterValue: FilterSelectOptions;
  date: DateType;
  setDate: Dispatch<SetStateAction<DateType>>;
  setFilterValue: Dispatch<SetStateAction<FilterSelectOptions>>;
}

const FilterModal = React.memo(
  ({ isOpen = false, onClose, filterData, handleApply, date, setDate, filterValue, setFilterValue }: FilterProps) => {
    const [displayValue, setDisplayValue] = useState<number>(0);
    const [selectedFilterValues, setSelectedFilterValues] = useState<FilterSelectOptions>({});
    const selectedFilterData = useMemo(() => filterData[displayValue], [filterData, displayValue]);
    const selectedIsMulti = useMemo(() => filterData[displayValue]?.isMulti, [filterData, displayValue]);
    const selectedIsDate = useMemo(() => filterData[displayValue]?.isDate, [filterData, displayValue]);

    const handleLabelClick = useCallback((index: number) => {
      setDisplayValue(index);
    }, []);

    const handleClearAll = useCallback(() => {
      setSelectedFilterValues({});
      setDate({ startDate: '', endDate: '' });
    }, []);

    useEffect(() => {
      setSelectedFilterValues(filterValue);

      handleApply();
    }, [filterValue]);

    const handleApplyFilter = () => {
      setFilterValue(selectedFilterValues);
    };

    const handleFilterData = useCallback(
      (filterFields: FilterSelectedOptions) => {
        const key = selectedFilterData.key;
        setSelectedFilterValues(prev => {
          if (selectedIsMulti) {
            const prevValue = isArray(prev[key]) ? prev[key] : [];

            if (isArray(prevValue)) {
              if (typeof filterFields.value === 'string' || typeof filterFields.value === 'boolean') {
                if (prevValue.includes(filterFields.value)) {
                  return { ...prev, [key]: prevValue.filter(item => item !== filterFields.value) };
                } else {
                  return { ...prev, [key]: [...prevValue, filterFields.value] };
                }
              }
            }
          } else {
            return { ...prev, [key]: filterFields.value };
          }

          return prev;
        });
      },
      [selectedFilterData, selectedIsMulti]
    );

    const handleFilterDate = useCallback(
      (filterFields: FilterSelectedOptions) => {
        const { label, value } = filterFields;
        setSelectedFilterValues(prev => {
          const updatedFilterValues = { ...prev };
          updatedFilterValues[label] = value;
          return updatedFilterValues;
        });
      },
      [setSelectedFilterValues]
    );

    const onSelectValue = useCallback(
      (label: string | boolean) => {
        const value = selectedFilterValues[selectedFilterData.key];

        if (isArray(value)) {
          return (value as Array<string | boolean>).includes(label);
        } else {
          return label === value;
        }
      },
      [selectedFilterValues, selectedFilterData]
    );

    useEffect(() => {
      handleFilterDate({ label: 'startDate', value: `${dateFormatter(date.startDate)}` });
      handleFilterDate({ label: 'endDate', value: `${dateFormatter(date.endDate)}` });
    }, [date]);

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: '1' }}
      >
        <StyledModalContainer>
          <StyledHeader>
            <span>Filters</span>
            <StyledHeaderText onClick={handleClearAll}>Clear all</StyledHeaderText>
          </StyledHeader>
          <StyledBody>
            <StyledLeftColumn $isDateFilter={selectedIsDate}>
              {filterData.map(({ label, key }, index) => {
                return (
                  <StyledLableContainer
                    $isSelected={selectedFilterData.key === key}
                    key={label}
                    onClick={() => handleLabelClick(index)}
                  >
                    <StyledLabel>{label}</StyledLabel>
                    <StyledIcon src={arrowRight} alt='export' />
                  </StyledLableContainer>
                );
              })}
            </StyledLeftColumn>
            <StyledRightColumn $isDateFilter={selectedIsDate}>
              {selectedFilterData?.value.map(({ label, value }, index) => (
                <StyledLabelInput key={`${label}-${value}-${index}`} $isDateFilter={selectedIsDate}>
                  {!selectedIsDate && (
                    <input
                      type={selectedIsMulti ? 'checkbox' : 'radio'}
                      value={label}
                      checked={onSelectValue(value)}
                      onChange={() => handleFilterData({ label, value })}
                    />
                  )}
                  {selectedIsDate && <DatePicker date={date} setDate={setDate} />}
                  {!selectedIsDate && label}
                </StyledLabelInput>
              ))}
            </StyledRightColumn>
          </StyledBody>
          <StyledFooter>
            <StyledButton onClick={onClose}>Cancel</StyledButton>
            <StyledButton $primary onClick={() => handleApplyFilter()}>
              Apply
            </StyledButton>
          </StyledFooter>
        </StyledModalContainer>
      </Modal>
    );
  }
);

export default FilterModal;
