import actionConstants from '../constants/';
import { LoaderStateProps } from '../type';

const loaderState: LoaderStateProps = {
  actionsLoading: [],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function loaderReducer(
  state = loaderState,
  action: { type: string; payload: { actionType: string; loaderMessage: string } }
) {
  let tempActionsLoading = [];

  switch (action.type) {
    case actionConstants.START_LOADER:
      return {
        actionsLoading: [...state.actionsLoading, action.payload.actionType],
        loaderMessage: action?.payload?.loaderMessage,
      };
    case actionConstants.STOP_LOADER:
      tempActionsLoading = [...state.actionsLoading];
      tempActionsLoading = tempActionsLoading.filter(a => a !== action.payload.actionType);
      return {
        actionsLoading: [...tempActionsLoading],
      };
    default:
      return state;
  }
}
