import apiEndpoints from '@src/lib/apiEndpoints';
import { ReqBodyInterface } from '@src/model/user';

import apiRequest from './api';

export const loginOtp = (data: { email: string } | { phoneNumber: string }, skipErrorMessage?: boolean) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.LOGIN_OTP}`,
    config: {
      skipErrorMessage: skipErrorMessage ?? false,
    },
    data,
  });
};

export const verifyLoginOtp = (data: { email: string } | { phoneNumber: string }, otp: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VERIFY_LOGIN_OTP}`,
    data: { ...data, otp },
  });
};

export const getUserLogout = () => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.USER_LOGOUT}`,
    data: {},
  });
};

export const refreshTokens = () => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.AUTH_REFRESH_TOKEN}`,
    data: {},
  });
};

export const saveUser = (data: ReqBodyInterface) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SAVE_USER}`,
    data,
  });
};
