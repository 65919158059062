import { getUserLogout, refreshTokens } from '@src/api/login';
import { getUserDetails } from '@src/api/user';

import ActionConstants from '../constants';

export const userAction = () => {
  const accessTokenExpiry = new Date();
  accessTokenExpiry.setMinutes(accessTokenExpiry.getMinutes() + 50);

  const payload = {
    serviceMethod: () => getUserDetails(),
    actionTypeSuccess: ActionConstants.USER_DETAILS_SUCCESS,
    actionTypeFailure: ActionConstants.USER_DETAILS_FAILURE,
    actionTypeRequest: ActionConstants.USER_DETAILS_REQUEST,
    extra: { accessTokenExpiry },
  };

  return { type: ActionConstants.USER_DETAILS_ACTION, payload };
};

export const clearUserData = () => {
  return {
    type: ActionConstants.CLEAR_USER_DETAILS,
  };
};

export const logoutAction = () => {
  const payload = {
    serviceMethod: () => getUserLogout(),
    actionTypeSuccess: ActionConstants.USER_LOGOUT_SUCCESS,
    actionTypeFailure: ActionConstants.USER_LOGOUT_FAILURE,
    actionTypeRequest: ActionConstants.USER_DETAILS_REQUEST,
  };

  return { type: ActionConstants.USER_LOGOUT_ACTION, payload };
};

export const refreshTokenAction = () => {
  const accessTokenExpiry = new Date();
  accessTokenExpiry.setMinutes(accessTokenExpiry.getMinutes() + 50);

  const payload = {
    serviceMethod: () => refreshTokens(),
    actionTypeSuccess: ActionConstants.REFRESH_TOKEN_SUCCESS,
    actionTypeFailure: ActionConstants.REFRESH_TOKEN_FAILURE,
    actionTypeRequest: ActionConstants.REFRESH_TOKEN_REQUEST,
    extra: { accessTokenExpiry },
  };

  return { type: ActionConstants.REFRESH_TOKEN_ACTION, payload };
};

export const startLoaderAction = (uniqueString: string) => {
  return {
    type: ActionConstants.START_LOADER,
    payload: { actionType: uniqueString, loaderMessage: 'Test' },
  };
};

export const stopLoaderAction = (uniqueString: string) => {
  return {
    type: ActionConstants.STOP_LOADER,
    payload: { actionType: uniqueString },
  };
};
