import styled from 'styled-components';

import { bgImagePackages, blastLogo, loginFrameLogo, textHighlight } from '@src/lib/imgUrl';
import { Breakpoints } from '@src/styles/breakpoints';

const StyledMainWrapper = styled.div`
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
`;

const StyledImageContainer = styled.div`
  display: flex;
  width: 34%;
  background: url(${loginFrameLogo}) no-repeat center/cover;
  ${Breakpoints.TABLET} {
    display: none;
  }
`;

export const StyledBackgroundImageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: ${({ theme }) => theme.space.get('l')} 0;
  ${Breakpoints.TABLET} {
    width: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${loginFrameLogo}) no-repeat center/cover;
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${Breakpoints.TABLET} {
    background: ${({ theme }) => theme.primaryColor.white};
    margin: 0px 20px;
    padding: 30px 0px;
    border-radius: 15px;
  }
`;

const StyledSection = styled.section<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap || '60px'};
  flex: 1;
  flex-wrap: wrap;
  ${Breakpoints.TABLET_SMALL} {
    display: grid;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledHeader = styled.div`
  font-family: 'the-seasons', Lato;
  font-size: ${({ theme }) => theme.fontSize.get('xl')};
  white-space: nowrap;
  color: ${({ theme }) => theme.primaryColor.black};
  ${Breakpoints.TABLET} {
    font-weight: 900;
    white-space: wrap;
    text-align: center;
  }
`;

const StyledBackLing = styled.span<{ $isVerification?: boolean; $blueHighlight?: boolean }>`
  background: url(${({ $blueHighlight }) => ($blueHighlight ? bgImagePackages : textHighlight)}) no-repeat bottom;
  background-size: 100% auto;
  font-weight: 600;
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  padding-bottom: ${({ theme, $isVerification }) =>
    $isVerification ? `${theme.fontSize.get('s')}` : `calc(${theme.fontSize.get('xs')}/2)`};
  ${Breakpoints.TABLET} {
    padding-bottom: ${({ theme, $isVerification }) =>
      $isVerification ? ` ${theme.fontSize.get('xs')}` : `calc( ${theme.fontSize.get('xs')}/2)`};
  }
`;

const StyledLabel = styled.div<{ error?: boolean; margin?: string }>`
  font-family: 'Lato', Lato;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-weight: 500;
  line-height: 30px;
  margin: ${({ margin }) => margin};
  color: ${({ theme, error }) => (error ? theme.primaryColor.red : theme.primaryColor.brandPaletteGray)};
  ${Breakpoints.TABLET} {
    font-weight: 500;
  }
`;

const StyledMaxWidthWrapper = styled.div<{ $isVerification?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 50%;
  max-width: 50%;
  gap: 60px;

  ${Breakpoints.TABLET} {
    min-width: 80%;
    max-width: 80%;
    gap: 20px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const StyledButton = styled.button<{ $loginColor?: boolean; $cursorPointer?: boolean }>`
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme, $loginColor }) =>
    $loginColor ? theme.primaryColor.lightGray2 : theme.primaryColor.shadePurple};
  color: ${({ theme, $loginColor }) => ($loginColor ? theme.primaryColor.black : theme.primaryColor.white)};
  border: none;
  border-radius: 8px;
  ${({ $cursorPointer }) =>
    $cursorPointer ? ` cursor: ${$cursorPointer ? 'pointer' : 'not-allowed'};` : `cursor: pointer;`}
  &:hover {
    color: ${({ theme }) => theme.primaryColor.white};
    background-color: ${({ theme }) => theme.primaryColor.deepCerulean};
  }
`;

const StyledEmailAddress = styled.span`
  font-weight: 700;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  width: fit-content;
  border: 2px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  backdrop-filter: blur(${({ theme }) => theme.space.get('3xs')});
  padding: ${({ theme }) => theme.space.get('2xs')};
  border-radius: ${({ theme }) => theme.fontSize.get('xs')};

  ${Breakpoints.TABLET} {
    border-radius: calc(${({ theme }) => theme.space.get('2xs')});
    padding: ${({ theme }) => theme.space.get('3xs')};
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.primaryColor.brandPaletteGray};
  }
`;

const StyledSvgImage = styled.img`
  height: auto;
  width: 170px;
  content: url(${blastLogo});

  ${Breakpoints.TABLET} {
    justify-self: center;
    width: 100px;
  }
`;

const StyledLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.get('3xs')};

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: ${({ theme }) => theme.primaryColor.brandPalette};
  }

  &::before {
    margin: 0 ${({ theme }) => theme.space.get('m')};

    ${Breakpoints.TABLET} {
      margin: 0 ${({ theme }) => theme.space.get('3xs')};
    }
  }

  &::after {
    margin: 0 ${({ theme }) => theme.space.get('m')};

    ${Breakpoints.TABLET} {
      margin: 0 ${({ theme }) => theme.space.get('3xs')};
    }
  }
`;

const StyledOtpInput = styled.input<{ $hasValue: boolean }>`
  text-align: center;
  background-color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  margin: 3px;
  width: 50px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid
    var(
      --Brand-Palette-Secondary-100,
      ${({ theme, $hasValue }) => ($hasValue ? theme.primaryColor.royalBlue : theme.primaryColor.borderBlue)}
    );
  &:focus {
    border: 1px solid var(--Brand-Palette-Secondary, ${({ theme }) => theme.primaryColor.royalBlue});
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.primaryColor.borderBlue};
  }
`;

const StyledOtpResend = styled.button`
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.primaryColor.cyanGreen};
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
`;

export {
  StyledBackLing,
  StyledButton,
  StyledContentContainer,
  StyledEmailAddress,
  StyledForm,
  StyledHeader,
  StyledIconWrapper,
  StyledImageContainer,
  StyledLabel,
  StyledLineContainer,
  StyledMainWrapper,
  StyledMaxWidthWrapper,
  StyledOtpInput,
  StyledOtpResend,
  StyledSection,
  StyledSvgImage,
};
