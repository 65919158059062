export default {
  LOGIN_OTP: '/auth/login-otp',
  VERIFY_LOGIN_OTP: '/auth/verify-login-otp',
  USER_LOGOUT: '/auth/logout',
  CREATE_GUEST_USER: '/auth/create-guest',
  CREATE_ANON_USER: '/auth/create-anon',
  AUTH_REFRESH_TOKEN: '/auth/refresh-tokens',
  CURRENT_USER: '/customer/self',
  UPDATE_CLIENT: '/customer',
  PACKAGE_LIST: '/package/list',
  DISH_LIST: '/dish/list',
  GET_PACKAGE: '/package',
  CREATE_EVENT: '/event/create',
  GET_EVENT: '/event',
  GET_EVENT_HISTORY: '/event/history',
  DELETE_EVENT: '/event/delete',
  CATEGORY_LIST: '/category/list',
  ALLERGEN_LIST: '/allergens/list',
  DEBIT_PAYMENT: '/transaction/debit',
  CREATE_ORDER: '/order/create',
  DISH: '/dish',
  ADDON: '/addon',
  SETTING_DETAILS: '/setting',
  ORDER_LIST_BY_ID: '/order/list',
  GET_ORDER: '/order',
  UPDATE_ORDER_BY_ID: '/order',
  ORDER_LIST: '/order/list',
  CREATE_BOOKING: '/booking/create',
  DELETE_BOOKING: '/booking/delete',
  UPDATE_NUMBER_OTP: '/auth/send-verification-otp',
  VERIFY_UPDATED_NUMBER: '/auth/verify-otp',
  GET_BOOKING_BY_ID: '/booking',
  GET_WEEKLY_MENU_TODAY: '/weekly-menu/todaysMenu',
  VERIFY_PROMO: '/order/verifyPromo',
  SAVE_USER: '/auth/heading-out',
  CREATE_FEEDBACK: '/feedback/create',
  FEEDBACK_LIST: '/feedback/list',

  // Box Management
  GET_BOXES: '/box/list',
  GET_BOX_BY_ID: '/box',

  // drop off menu
  GET_DROPOFF_MENU: '/dropoffmenu',

  // payment
  ONE_TIME_PAYMENT: '/auth/create-anon',
  GET_USER_BY_ID: '/customer',

  // Daily Menu
  DAILY_MENU_BY_ID: '/daily-menu',
};
