import { useNavigate } from 'react-router-dom';

import Modal from '@mui/material/Modal';

import { UiRoutes } from '@src/lib/constants';
import { theme } from '@src/styles/theme';

import { StyledButton } from '../FormComponent/styles';

import { StyledButtonWrapper, StyledContentWrapper, StyledModalContainer } from './style';

interface ModalProps {
  isOpen: boolean;
  modalName: string;
  onRequestClose: () => void;
  onSave: CallableFunction;
  isLogoutAction?: boolean;
  closeAfterSave?: boolean;
  performLogoutActions?: () => void;
}

const DeleteModal: React.FC<ModalProps> = ({
  isOpen,
  modalName,
  onRequestClose,
  onSave,
  isLogoutAction,
  closeAfterSave = true,
  performLogoutActions,
}) => {
  const navigate = useNavigate();

  const handleSave = async () => {
    await onSave();

    if (isLogoutAction && performLogoutActions) {
      performLogoutActions();
      // navigate(UiRoutes.HOME);
      navigate(UiRoutes.PACKAGESFS);
    }

    closeAfterSave && handleClose();
  };

  const handleClose = () => {
    onRequestClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledModalContainer>
        <StyledContentWrapper>{`${modalName}`}</StyledContentWrapper>
        <StyledButtonWrapper>
          <StyledButton type='button' onClick={handleSave}>
            Yes
          </StyledButton>
          <StyledButton
            onClick={handleClose}
            $bgColor={theme.primaryColor.white}
            color={theme.primaryColor.midnight}
            border
          >
            No
          </StyledButton>
        </StyledButtonWrapper>
      </StyledModalContainer>
    </Modal>
  );
};

export default DeleteModal;
